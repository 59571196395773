<template>
  <div>
    <v-container>
      <v-row class="justify-space-between">
        <v-col
          cols="12"
          sm="6"
          md="8"
          lg="9"
          order="12"
          order-sm="first"
        >
          <h2 class="mb-6 d-flex justify-space-between">
            {{ $t("Boutiques") }}
          </h2>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-text-field
            v-model="query"
            outlined
            dense
            hide-details
            depressed
            clearable
            :placeholder="$t('Researcher...')"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-for="item in items"
          :key="item.id"
          cols="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
        >
          <base-card class="vendor-card p-relative">
            <v-img
              height="250"

              :src="item.cover || '@/assets/images/faces/ralph.png'"
              gradient="rgba(43, 52, 69,.8), rgba(43, 52, 69,.8)"
            />
            <div class="p-absolute top-0 ma-5">
              <h3 class="white--text mb-0">
                {{ item.pseudo }}
              </h3>
              <v-rating
                v-if="item.rating"
                :value="item.rating || 0"
                color="amber"
                dense
                half-increments
                readonly
                class="mb-3"
                size="16"
              />
              <div class="d-flex align-start mb-2">
                <v-icon
                  small
                  class="mr-2"
                  color="white"
                >
                  mdi-map-marker
                </v-icon>
                <span class="white--text">{{ item.postal_code }} {{ item.city }} {{ item.country_code }}</span>
              </div>
<!--              <div class="d-flex align-start mb-2">
                <v-icon
                  small
                  class="mr-2"
                  color="white"
                >
                  mdi-phone
                </v-icon>
                <span class="white&#45;&#45;text">{{ item.tel }}</span>
              </div>-->
            </div>
            <v-card-text class="p-relative">
              <div class="vendor-avatar">
                <v-avatar size="56">
                  <v-img
                    :lazy-src="item.avatar_thumb"
                    :src="item.avatar"
                    alt=""
                  />
                </v-avatar>
              </div>
            </v-card-text>
            <v-card-actions class="d-flex justify-end align-center p-relative">
              <div class="vendor-btn mr-3">
                <v-btn
                  icon
                  color="grey darken-1"
                  :to="`/shops/${item.id}`"
                >
                  <img
                    v-svg-inline
                    class="icon"
                    src="@/assets/images/icons/arrow-long-right.svg"
                    alt=""
                  >
                </v-btn>
              </div>
            </v-card-actions>
          </base-card>
        </v-col>
        <v-col
          v-for="sk in [1,2,3,4,5,6,7,8,9,10]"
          v-if="loading"
          :key="sk"
          cols="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
        >
          <template>
            <v-skeleton-loader
              type="image"
            />
            <v-skeleton-loader
              type="article"
              height="150"
            />
          </template>
        </v-col>
        <!--        <v-col cols="12">
          <v-data-iterator
            :items="items"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="item in props.items"
                  :key="item.name"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="4"
                  xl="3"
                >
                  <base-card class="vendor-card p-relative">
                    <v-img
                      height="250"

                      :src="item.img"
                      gradient="rgba(43, 52, 69,.8), rgba(43, 52, 69,.8)"
                    />
                    <div class="p-absolute top-0 ma-5">
                      <h3 class="white&#45;&#45;text mb-0">
                        {{ item.title }}
                      </h3>
                      <v-rating
                        :value="4.5"
                        color="amber"
                        dense
                        half-increments
                        readonly
                        class="mb-3"
                        size="16"
                      />
                      <div class="d-flex align-start mb-2">
                        <v-icon
                          small
                          class="mr-2"
                          color="white"
                        >
                          mdi-map-marker
                        </v-icon>
                        <span class="white&#45;&#45;text">845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark</span>
                      </div>
                      <div class="d-flex align-start mb-2">
                        <v-icon
                          small
                          class="mr-2"
                          color="white"
                        >
                          mdi-phone
                        </v-icon>
                        <span class="white&#45;&#45;text">(613) 343-9004</span>
                      </div>
                    </div>
                    <v-card-text class="p-relative">
                      <div class="vendor-avatar">
                        <v-avatar size="56">
                          <img
                            :src="item.avatar"
                            alt=""
                          >
                        </v-avatar>
                      </div>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end align-center p-relative">
                      <div class="vendor-btn mr-3">
                        <v-btn
                          icon
                          color="grey darken-1"
                        >
                          <img
                            v-svg-inline
                            class="icon"
                            src="@/assets/images/icons/arrow-long-right.svg"
                            alt=""
                          >
                        </v-btn>
                      </div>
                    </v-card-actions>
                  </base-card>
                </v-col>
              </v-row>
            </template>

            <template v-slot:footer>
              <v-row
                class="my-5 me-1"
                align="center"
                justify="center"
              >
                <v-spacer />

                <span
                  class="mr-4
                    grey&#45;&#45;text"
                >
                  Page {{ page }} of {{ numberOfPages }}
                </span>
                <v-btn
                  fab
                  :disabled="page == 1"
                  small
                  color="primary"
                  class="mr-1"

                  @click="formerPage"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  fab

                  :disabled="page == numberOfPages"
                  small
                  color="primary"
                  class="ml-1"
                  @click="nextPage"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>-->
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import CardCart from '@/components/cartCard/CardCart'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  export default {
    components: {
      CardCart,
    },
    data () {
      return {
        tab: null,
        page: 1,
        itemsPerPage: 8,
        loading: false,
        total: 0,
        loadingShop: false,
        loadingCategories: false,
        pagination: { totalItems: 0 },
        query: '',
        awaitingSearch: '',
        bottom: false,
        timeout: null,
        filterProductList: [],
        filterProduct: [],
        filter: {
          categories: [],
          price: [],
        },
        selected: [],
        category: {},
        categories: [],
        default_url: '/shops',
        url: '/shops',
        items: [],
      }
    },
    computed: {
      ...mapGetters(['getProducts', 'getCategory', 'getRatings']),
      path () {
        return this.$route.path
      },
      search () {
        return { ...this.query, ...this.filter.categories }
      },
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },

    },
    watch: {
      path (val) {
        this.url = this.default_url
        this.items = []
        this.pagination = { totalItems: 0 }
        this.loading = false
        this.loadData()
      },
      search: {
        handler () {
          clearTimeout(this.timeout)
          this.url = this.default_url
          this.items = []
          this.pagination = { totalItems: 0 }
          this.loading = false
          this.timeout = setTimeout(() => {
            this.loadData()
          }, 1300) // 1 sec delay
        },
        deep: true,
      },
    },
    created () {
      // this.url = this.default_url
      // window.addEventListener('scroll', this.handleScroll)
      window.addEventListener('scroll', () => {
        this.bottom = this.bottomVisible()
        if (!this.loading && this.bottom && this.items.length < this.pagination.totalItems) {
          this.loadData()
        }
      })
      this.loadData()
    },
    destroyed () {
      this.setValue({
        type: 'breadcrumbs',
        value: [],
      })
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`

        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      getShop () {
        this.loadingShop = true
        axios.get(this.$route.path).then((response) => {
          if (response.data && response.data.id) {
            this.shop = response.data
            this.setShop()
          }
        }).then(() => {
          this.loadingShop = false
        })
      },
      bottomVisible () {
        const scrollY = window.scrollY
        const visible = document.documentElement.clientHeight
        const pageHeight = document.documentElement.scrollHeight
        /* console.log('scrolly', scrollY)
        console.log('visible', visible) */
        return visible < scrollY
        /* const bottomOfPage = (visible + scrollY) - 1000 >= pageHeight
        return bottomOfPage || pageHeight < visible */
        const bottomOfPage = (visible + scrollY) - 1000 >= pageHeight
        return bottomOfPage || pageHeight < visible
      },
      loadData () {
        if (this.url != null) {
          this.pagination.page += 1
          this.getData()
        }
      },
      reLoadData () {
        this.url = this.default_url
        this.getData()
      },

      async getData () {
        this.loading = true
        return new Promise((resolve, reject) => {
          var _this = this
          // this.setValue({type:'posts',value:[]});
          axios({
            method: 'get',
            url: _this.url,
            params: {
              orderBy: this.order,
              /* categories: this.filter.categories, */
              term: this.query,
              page: this.pagination.page || 1,
              /* direction: this.pagination.descending ? "DESC" : "ASC", */
              per_page: this.pagination.itemsPerPage,
            },
            data: {},
            validateStatus: (status) => {
              return true
            },
          }).then((response) => {
            if (response.data.error) {
              // this.toast(response.data.message, 'red');
              return false
            }
            /* if(!this.items[0].id){
              this.setValue({type:"user_posts",value:[]});
            } */
            const items = response.data.data
            const total = response.data.total
            this.loading = false

            this.pagination = {
              page: response.data.current_page,
              itemsPerPage:
                response.data.per_page,
              totalItems:
                total,
            }

            // this.items = data.items;

            _this.url = response.data.next_page_url || _this.url

            for (var i = 0; i < items.length; i++) {
              // _this.pushItem(items[i])

              var item = items[i]
              item = {
                ...item,
                title: item.name,
                categoryName: item.category ? item.category.name : '',
                amount: item.price_with_tax,
                original_price: item.price_with_tax,
                promo: item.promo,
                qty: 0,
              }
              item = this.processProduct(item)
              var index = this.items.findIndex(e => e.id === item.id)
              if (index < 0) {
                this.items.push(item)
              }
            }
            // _this.total += total;
            /* if (_this.bottomVisible() && this.loadable) {
                 _this.loadData()
             } */
          }).catch(error => {
            // this.toast("Error", "red");
            console.log(error)
          }).catch(error => {
            // this.toast(this.$t("Error"), "red");
            console.log(error)
          }).then(() => {
            _this.loading = false
          })
        })
      },
      relevance (amount) {
        if (this.select == 'Low to High') {
          this.items = this.items.sort((a, b) => a[amount] < b[amount] ? -1 : 1)
        } else {
          this.items = this.items.sort((a, b) => a[amount] > b[amount] ? -1 : 1)
        }
      },
      // fullProducts () {
      //   this.items = this.getProducts
      // },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      season (val) {
        return this.range[val]
      },

      filterCategory (category) {
        this.items = this.getProducts.filter(x => this.selected.includes(x.category))
        if (this.selected.length <= 0) {
          this.items = this.getProducts
        }
      },
      filterRatings (rating) {
        this.items = this.getProducts.filter(x => this.selected.includes(x.rating))
        if (this.selected.length <= 0) {
          this.items = this.getProducts
        }
      },
      productRate (rate) {
        this.items = this.getProducts.filter(x => x.rating == rate.rates)
        // this.items = this.filterProduct.sort((a, b) => a[rate.rates] < b[rate.rates] ? -1 : 1)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .vendor-card {
    .vendor-avatar {
      position: absolute;
      top: -28px;
      left: 20px;
      img {
        border: 4px solid #fff;
      }
    }
    .vendor-btn {
      position: absolute;
      bottom: 6px;
    }
  }

</style>
